<template>
  <b-card class="m-0" @click="behaviourRoute">
    <p class="d-inline-flex w-100 justify-content-between mt-25">
      <span class="text-primary font-weight-bold">
        {{ behaviour.name }}
      </span>
      <b-badge variant="info">
        {{ behaviour.type }}
      </b-badge>
    </p>
    <span>Behaviour tree: <span class="text-primary">{{ behaviour.tree_name }}</span></span>
  <!--<pre>{{ behaviour }}</pre>-->
  </b-card>
</template>

<script>
export default {
  props: {
    behaviour: {
      type: Object,
      default: null,
    },
  },
  methods: {
    behaviourRoute() {
      const bTree = this.behaviour.in_bt
      const bNode = this.behaviour.id
      this.$router.push(
        {
          name: 'joint_mbse_tree_focus',
          params: { behaviourTreeId: bTree },
          query: { focus: bNode },
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark-layout {
    .hoverCard:hover {
      background-color: #343e5a;
    }
  }

  .hoverCard:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
</style>
