<template>
  <b-card no-body class="mt-2">
    <b-card-header
      v-if="issue && !editMode"
      class="email-detail-head"
    >
      <div class="d-inline-flex">
        <!-- Classification icon badge -->
        <div>
          <b-avatar
            class="avatar"
            :class="`bg-${resolveSeverityColor(issue.severity)} mr-75`"
            size="30"
          >
            <feather-icon
              :icon="resolveClassificationIcon(issue.classification)"
              size="16"
            />
          </b-avatar>
        </div>

        <!-- Issue Name -->
        <div class="pt-25">
          <span
            id="issue-name"
            class="v-html-fixes font-medium-1 font-weight-bold"
            v-sanitized-html="issue.name"
          />
        </div>
      </div>
    </b-card-header>

    <!-- Issue description -->
    <b-card-body>
      <div v-if="!editMode" class="mt-2">
        <span
          v-if="issue.description !== '<p></p>' && issue.description !== ''"
          class="v-html-fixes"
          v-sanitized-html="issue.description"
        />
        <span v-else class="text-muted font-small-3">
          This issue has no description...
        </span>
      </div>
      <div v-else>
        <b-row no-gutters class="w-100">
          <b-form-group
            label="Issue summary"
            label-for="add_issue_rich_1"
            class="w-100"
          >
            <tip-tap-editor
              id="add_issue_rich_1"
              v-model="name"
              class="shadow"
              :allow-image-upload="false"
            />
          </b-form-group>
        </b-row>
        <b-row no-gutters class="w-100 mt-1">
          <b-form-group
            label="Description"
            label-for="add_issue_rich_2"
            class="w-100"
          >
            <tip-tap-editor
              id="add_issue_rich_2"
              v-model="description"
              class="shadow"
              :allow-image-upload="false"
            />
          </b-form-group>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <label for="add_issue_select_1">Classification</label>
            <b-form-select
              id="add_issue_select_1"
              v-model="classification"
              class="shadow"
              :options="issueClassifications"
            />
          </b-col>
          <b-col>
            <label for="add_issue_select_2">Severity</label>
            <b-form-select
              id="add_issue_select_2"
              v-model="severity"
              class="shadow"
              :options="issueSeverities"
            />
          </b-col>
          <b-col>
            <label for="add_issue_select_3">Status</label>
            <b-form-select
              id="add_issue_select_3"
              v-model="status"
              class="shadow"
              :options="issueStatuses"
            />
          </b-col>
        </b-row>

        <!-- Save/Cancel edit buttons-->
        <b-row v-if="editMode" no-gutters class="w-100 mt-1">
          <b-col cols="12" class="mt-50">
            <b-button
              variant="outline-success"
              size="sm"
              class="float-right"
              @click="updateIssue"
            >
              Save Changes
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              class="mr-50 float-right"
              @click="cancelEdit"
            >
              Discard
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

    <!-- Create and Update details -->
    <b-card-footer class="py-50">
      <div class="w-100">
        <small
          v-if="issue.updated && issue.updated_by"
          class="text-secondary mr-1 d-inline"
        >
          Updated {{ formatDate(issue.updated) }} by {{ getUserUsername(issue.updated_by) }}
        </small>

        <small class="text-secondary mr-1 d-inline float-right">
          Raised {{ formatDate(issue.created) }} by {{ (getUserUsername(issue.created_by)) }}
        </small>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BAvatar, BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
// eslint-disable-next-line import/extensions
import issueHelpers from './issueHelpers.js'

export default {
  components: {
    TipTapEditor,
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
  },
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    issue: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { resolveClassificationIcon, resolveSeverityColor } = issueHelpers()

    // Issue values
    const name = ref()
    const description = ref()
    const classification = ref()
    const severity = ref()
    const status = ref()
    watch(() => props.issue, () => {
      name.value = props.issue.name
      description.value = props.issue.description
      classification.value = props.issue.classification
      severity.value = props.issue.severity
      status.value = props.issue.status
    }, { immediate: true })

    // Revert unsaved changes
    const cancelEdit = () => {
      name.value = props.issue.name
      description.value = props.issue.description
      classification.value = props.issue.classification
      severity.value = props.issue.severity
      status.value = props.issue.status
      emit('set-edit-mode', false)
    }

    // Save changes
    const updateIssue = () => {
      const issueId = props.issue.id
      const payload = {
        data: {
          name: name.value,
          description: description.value,
          classification: classification.value,
          severity: severity.value,
          status: status.value,
        },
        id: issueId,
      }
      store.dispatch('issues/updateIssue', payload)
        .then(() => {
          store.dispatch('issues/selectIssue', issueId)
          emit('updated')
        })
        .finally(() => {
          emit('set-edit-mode', false)
        })
    }

    return {
      formatDate,
      // issueHelpers
      resolveSeverityColor,
      resolveClassificationIcon,

      // Vuex
      issueClassifications: computed(() => store.getters['constants/issueClassifications']),
      issueSeverities: computed(() => store.getters['constants/issueSeverities']),
      issueStatuses: computed(() => store.getters['constants/issueStatuses']),

      // Edit Mode
      name,
      description,
      classification,
      severity,
      status,
      cancelEdit,
      updateIssue,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/style.scss";

.v-html-fixes ::v-deep {
  @extend .v-html-fixes-base;
}
</style>
