<template>
  <div>
    <b-card class="hoverCard shadow border-bottom border-left" @click="testRoute">
      <b-card-title class="font-small-4 font-weight-bolder">
        {{ test.ref_id + ' - ' + test.name }}
      </b-card-title>
      <b-card-sub-title class="font-small-3 mb-1">
        {{ test.id }}
      </b-card-sub-title>
      <b-card-text>
        {{ test.test }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    test: {
      type: Object,
      default: null,
    },
  },
  methods: {
    testRoute() {
      this.$router.push(
        {
          name: 'model_test_focus',
          params: { testId: this.test.id },
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark-layout {
    .hoverCard:hover {
      background-color: #343e5a;
    }
  }

  .hoverCard:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
</style>
