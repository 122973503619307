import store from '@/store'

const classificationMap = [
  { key: 'ambiguous', icon: 'HelpCircleIcon' },
  { key: 'inaccurate', icon: 'TargetIcon' },
  { key: 'incomplete', icon: 'LoaderIcon' },
  { key: 'inconsistent', icon: 'ShuffleIcon' },
  { key: 'query', icon: 'SearchIcon' },
  { key: 'designquery', icon: 'FeatherIcon' },
  { key: 'minorquery', icon: 'ArrowDownIcon' },
  { key: 'redundant', icon: 'CopyIcon' },
]

const severityMap = [
  { key: 'critical', index: '1', color: 'danger' },
  { key: 'major', index: '2', color: 'danger' },
  { key: 'minor', index: '3', color: 'warning' },
  { key: 'negligible', index: '4', color: 'secondary' },
  { key: 'query', index: '5', color: 'info' },
  { key: 'designquery', index: '6', color: 'info' },
  { key: 'minorquery', index: '7', color: 'info' },
]

const statusMap = [
  { key: 'new', color: 'danger' },
  { key: 'open', color: 'warning' },
  { key: 'deferred', color: 'light-info' },
  { key: 'withdrawn', color: 'light-secondary' },
  { key: 'closed', color: 'light-secondary' },
]

export default function issueHelpers() {
  const resolveClassificationIcon = value => {
    if (value) {
      const classification = value.replace(' ', '')
        .toLowerCase()
      const foundClassification = classificationMap.find(cm => cm.key === classification)
      return foundClassification ? foundClassification.icon : 'LayersIcon'
    }
    return 'LayersIcon'
  }

  const resolveClassificationDescriptor = value => {
    if (value) {
      return store.state.constants.issueClassifications.find(e => e.name === value).descriptor
    }
    return ''
  }

  const resolveSeverityColor = value => {
    if (value) {
      const severity = value.replace(' ', '')
        .toLowerCase()
      const foundSeverity = severityMap.find(sm => sm.key === severity)
      return foundSeverity ? foundSeverity.color : 'dark'
    }
    return 'dark'
  }

  const resolveStatusColor = value => {
    if (value) {
      const status = value.replace(' ', '')
        .toLowerCase()
      const foundStatus = statusMap.find(sm => sm.key === status)
      return foundStatus ? foundStatus.color : ''
    }
    return ''
  }

  return {
    resolveClassificationIcon,
    resolveClassificationDescriptor,
    resolveSeverityColor,
    resolveStatusColor,
  }
}
