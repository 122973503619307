<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="$emit('close-issue-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ selectedIssue.display_id }}
          <span class="mx-75">:</span>
          <b-badge
            :variant="resolveStatusColor(selectedIssue.status)"
          >
            {{ selectedIssue.status }}
          </b-badge>
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">

        <b-badge
          pill
          class="mr-50"
          :variant="`${resolveSeverityColor(selectedIssue.severity)}`"
        >
          <span class="font-medium-1">
            {{ selectedIssue.severity }}
          </span>
        </b-badge>

        <b-badge pill variant="light-primary" class="mr-50">
          <span class="font-medium-1">
            <feather-icon
              :icon="resolveClassificationIcon(selectedIssue.classification)"
              style="margin-top: 0.1rem"
            />
            {{ resolveClassificationDescriptor(selectedIssue.classification) }}
          </span>
        </b-badge>

        <!-- Trigger Issue edit mode -->
        <feather-icon
          id="issue-edit-button"
          v-b-tooltip.hover.top="'Edit Issue'"
          icon="EditIcon"
          class="ml-75"
          :class="editMode ? 'text-muted' : 'text-success cursor-pointer'"
          size="17"
          @click="editMode = true"
        />

        <!-- Go to Issue history -->
        <font-awesome-icon v-b-tooltip.hover.top="'Issue History'"
                           :icon="['fas', 'timeline']"
                           right
                           class="ml-75 text-warning cursor-pointer"
                           @click="issueHistory"
        />

        <!-- Show Previous Issue -->
        <feather-icon
          icon="ChevronLeftIcon"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !openedIssueMeta.hasPreviousIssue}"
          @click="$emit('change-opened-issue', 'previous')"
        />

        <!-- Show Next Issue -->
        <feather-icon
          icon="ChevronRightIcon"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !openedIssueMeta.hasNextIssue}"
          @click="$emit('change-opened-issue', 'next')"
        />
      </div>
    </div>

    <!-- Issue Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >
      <!-- Issue details card -->
      <b-row>
        <b-col cols="12">
          <issue-details-card
            ref="issueInfoCard"
            :edit-mode="editMode"
            :issue="selectedIssue"
            @updated="$emit('updated')"
            @set-edit-mode="setEditMode"
          />
        </b-col>
      </b-row>

      <!-- Associated requirements/entities/behaviours -->
      <h5>Associations</h5>
      <b-card no-body>
        <app-collapse type="border">
          <app-collapse-item title="Requirements">
            <template #header>
              <b-button
                v-b-modal.associator-generic-requirements
                variant="flat-success"
                size="sm"
                class="p-25 mr-75"
                title="Update Requirement Associations"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
              <b-badge class="mr-50" :variant="selectedIssue.requirements.length ? 'light-primary' : 'muted'">
                {{ selectedIssue.requirements.length ? selectedIssue.requirements.length : '-' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Requirement{{ selectedIssue.requirements.length === 1 ? '' : 's' }}
              </span>
            </template>

            <div>
              <div v-for="req in selectedIssue.requirements" :key="req.id" class="mb-50">
                <issue-requirement-card :requirement="req" />
              </div>
              <div v-if="!selectedIssue.requirements || selectedIssue.requirements.length === 0" class="mb-50">
                <span class="text-muted">No associated requirements...</span>
              </div>
            </div>
          </app-collapse-item>

          <app-collapse-item title="Entities">
            <template #header>
              <b-button
                v-b-modal.associator-generic-entities
                variant="flat-success"
                size="sm"
                class="p-25 mr-75"
                title="Update Entity Associations"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
              <b-badge class="mr-50" :variant="selectedIssue.components.length ? 'light-primary' : 'muted'">
                {{ selectedIssue.components.length ? selectedIssue.components.length : '-' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Entit{{ selectedIssue.components.length === 1 ? 'y' : 'ies' }}
              </span>
            </template>
            <div>
              <div v-for="comp in selectedIssue.components" :key="comp.id" class="mb-50">
                <issue-entity-card :entity="comp" />
              </div>
              <div v-if="!selectedIssue.components || selectedIssue.components.length === 0" class="mb-50">
                <span class="text-muted">No associated entities...</span>
              </div>
            </div>
          </app-collapse-item>

          <app-collapse-item title="Behaviours">
            <template #header>
              <b-button
                variant="flat-muted"
                size="sm"
                class="p-25 mr-75"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
              <b-badge class="mr-50" :variant="selectedIssue.behaviours.length ? 'light-primary' : 'muted'">
                {{ selectedIssue.behaviours.length ? selectedIssue.behaviours.length : '-' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Behaviour{{ selectedIssue.behaviours.length === 1 ? '' : 's' }}
              </span>
            </template>

            <div>
              <div v-for="bn in selectedIssue.behaviours" :key="bn.id" class="mb-50">
                <issue-behaviour-card :behaviour="bn" />
              </div>
              <div v-if="!selectedIssue.behaviours || selectedIssue.behaviours.length === 0" class="mb-2">
                <span class="text-muted">No associated behaviours...</span>
              </div>
            </div>
          </app-collapse-item>

          <app-collapse-item title="Interfaces">
            <template #header>
              <b-button
                v-b-modal.associator-generic-interfaces
                variant="flat-success"
                size="sm"
                class="p-25 mr-75"
                title="Update Interface Associations"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
              <b-badge class="mr-50" :variant="selectedIssue.interfaces.length ? 'light-primary' : 'muted'">
                {{ selectedIssue.interfaces.length ? selectedIssue.interfaces.length : '-' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Interface{{ selectedIssue.interfaces.length === 1 ? '' : 's' }}
              </span>
            </template>
            <div>
              <div v-for="iface in selectedIssue.interfaces" :key="iface.id" class="mb-50">
                <issue-interface-card :iface="iface" />
              </div>
              <div v-if="!selectedIssue.interfaces || selectedIssue.interfaces.length === 0" class="mb-50">
                <span class="text-muted">No associated interfaces...</span>
              </div>
            </div>
          </app-collapse-item>
          <app-collapse-item title="Test Cases">
            <template #header>
              <b-button
                v-b-modal.associator-generic-test-cases
                variant="flat-success"
                size="sm"
                class="p-25 mr-75"
                title="Update Test Case Associations"
              >
                <feather-icon icon="LinkIcon" />
              </b-button>
              <b-badge class="mr-50" :variant="selectedIssue.tests.length ? 'light-primary' : 'muted'">
                {{ selectedIssue.tests.length ? selectedIssue.tests.length : '-' }}
              </b-badge>
              <span class="lead collapse-title mr-auto">
                Test{{ selectedIssue.tests.length === 1 ? '' : 's' }}
              </span>
            </template>
            <div>
              <div v-for="t in selectedIssue.tests" :key="t.id" class="mb-50">
                <issue-test-card :test="t" />
              </div>
              <div v-if="!selectedIssue.tests || selectedIssue.tests.length === 0" class="mb-50">
                <span class="text-muted">No associated tests...</span>
              </div>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-card>

      <!-- Comments card -->
      <h5>Comments</h5>
      <b-row>
        <b-col cols="12">
          <b-card>
            <comment-card
              :parent-id="selectedIssue.id"
              :parent-label="'Issue'"
            />
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

    <!-- Requirements -->
    <associator-generic
      name="Requirements"
      :all-items="allRequirements"
      :associated-items="selectedIssue.requirements.map(requirementToIdText)"
      @associated="allocateIssue('Requirement', $event)"
    />
    <!-- Entities -->
    <GenericAssociator
      id="associator-generic-entities"
      :fetch-filter-fn="fetchSharedModels"
      :fetch-fn="modelId => fetchComponents(modelId, '')"
      :update-fn="payload => linkComponent(payload)"
      :remove-fn="payload => unlinkComponent(payload)"
      :initial-prefilter="model"
      :initial-list="selectedIssue.components"
      :instant-save="true"
      prefilter-label="name"
      type-name="Entities"
    >
      <template #left="cpt">
        <b-badge class="mr-1" variant="info">
          ({{ cpt.labels ? cpt.labels[1] : 'Entity' }})
        </b-badge>
        &nbsp;
        <abbr :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ cpt.name }} </abbr>
      </template>
      <template #right="{ cpt }">
        <b-badge v-if="cpt.model !== model.id" class="mr-1" variant="primary" :title="`${cpt.model}`">
          <feather-icon
            icon="ExternalLinkIcon"
            size="16"
          />
        </b-badge>
        <b-badge class="mr-1" variant="info">
          {{ cpt.labels ? cpt.labels[1] : 'Entity' }}
        </b-badge>
        <abbr :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ ' ' }}{{ cpt.name }} </abbr>
      </template>
    </GenericAssociator>
    <!-- Interfaces -->
    <associator-generic
      name="Interfaces"
      :all-items="allInterfaces"
      :associated-items="selectedIssue.interfaces.map(interfaceToIdText)"
      @associated="allocateIssue('Interface', $event)"
    />
    <!-- Tests -->
    <associator-generic
      name="Test Cases"
      :all-items="allTests"
      :associated-items="selectedIssue.tests.map(testToIdText)"
      @associated="allocateIssue('TestCase', $event)"
    />
  </div>
</template>

<script>
// Directives and Template stuff
import {
  BRow,
  BCol,
  BBadge,
  BCard,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'

// Modals
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'

// Components
import CommentCard from '@/components/Comments/CommentCard.vue'
import IssueRequirementCard from '@/components/Issues/IssueRequirementCard.vue'
import IssueEntityCard from '@/components/Issues/IssueEntityCard.vue'
import IssueBehaviourCard from '@/components/Issues/IssueBehaviourCard.vue'
import IssueInterfaceCard from '@/components/Issues/IssueInterfaceCard.vue'
import IssueTestCard from '@/components/Issues/IssueTestCard.vue'
import IssueDetailsCard from './IssueDetailsCard.vue'

// Scripts
import issueHelpers from './issueHelpers'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBadge,
    // BCard,
    // BLink,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,

    // SFC
    IssueDetailsCard,

    // Components
    CommentCard,
    IssueRequirementCard,
    IssueEntityCard,
    IssueBehaviourCard,
    IssueInterfaceCard,
    IssueTestCard,

    // Modals
    AssociatorGeneric, // not to be confused with
    GenericAssociator,
  },
  props: {
    issueViewData: {
      type: Object,
      required: true,
    },
    openedIssueMeta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    issueHistory() {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.issueViewData.id,
          },
        },
      )
    },
  },
  setup(props, { emit }) {
    const {
      resolveClassificationIcon,
      resolveClassificationDescriptor,
      resolveSeverityColor,
      resolveStatusColor,
    } = issueHelpers()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Main watch prop
    watch(() => props.issueViewData.id, () => {
      // Reselect Issue on issue change
      store.dispatch('issues/selectIssue', props.issueViewData.id)
        .then(() => emit('updated'))
    }, { immediate: true })

    // Edit Issue mode
    const editMode = ref(false)
    const setEditMode = value => {
      editMode.value = value
    }

    async function fetchSharedModels() {
      return [model.value, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    }
    async function fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    }
    async function linkComponent(cpt) {
      const allocated = selectedIssue.value.components.map(c => c.id)
      allocated.push(cpt.id)
      const payload = {
        label: 'Component',
        allocated,
        issueId: props.issueViewData.id,
      }
      await store.dispatch('issues/allocateIssue', payload)
      await store.dispatch('issues/selectIssue', props.issueViewData.id)
    }
    async function unlinkComponent(cptId) {
      await coreService.componentApi.deleteComponentAllocation(cptId, props.issueViewData.id)
      await store.dispatch('issues/selectIssue', props.issueViewData.id)
    }

    const model = computed(() => store.state.model)
    const selectedIssue = computed(() => store.state.issues.selected_issue)

    const allRequirements = computed(() => store.state.requirements.requirements.map(requirementToIdText))
    const allInterfaces = computed(() => store.state.interfaces.interfaces.map(interfaceToIdText))
    const allTests = computed(() => store.state.tests.tests.map(testToIdText))

    const requirementToIdText = req => ({
      value: {id: req.id},
      text: `${req.display_id} - ${req.text || req.object_text}`,
    })
    const interfaceToIdText = iface => ({
      value: { id: iface.id },
      text: `${iface.display_id} - ${iface.name}`,
    })
    const testToIdText = test => ({
      value: { id: test.id },
      text: `${test.ref_id} - ${test.name}`,
    })

    const getData = () => {
      store.dispatch('requirements/getRequirementsSimple')
      store.dispatch('domainModel/getComponentsSimple')
      store.dispatch('interfaces/fetchInterfaces')
      store.dispatch('tests/getTests')
    }
    getData()

    const allocateIssue = (label, links) => {
      const payload = {
        issueId: selectedIssue.value.id,
        label,
        allocated: links,
      }
      store.dispatch('issues/allocateIssue', payload).then(() => {
        store.dispatch('issues/selectIssue', selectedIssue.value.id)
      })
    }

    return {
      model,
      selectedIssue,

      fetchSharedModels,
      fetchComponents,
      linkComponent,
      unlinkComponent,

      // UI
      perfectScrollbarSettings,

      // issueHelpers
      resolveClassificationIcon,
      resolveClassificationDescriptor,
      resolveSeverityColor,
      resolveStatusColor,

      // Edit Issue mode
      editMode,
      setEditMode,

      // Associator Stuff
      allRequirements,
      allInterfaces,
      allTests,
      requirementToIdText,
      interfaceToIdText,
      testToIdText,
      allocateIssue,
    }
  },
}
</script>
